import insightEventWithObjectData from './insightEventWithObjectData';
import prepareObjectData from './prepareObjectData';

const handleNonAlgoliaInsightEvent = (
	maxRecordsQuantity,
	skus,
	notAlgoliaSearchEventType,
) => {
	if (skus.length >= maxRecordsQuantity) skus.length = maxRecordsQuantity;

	insightEventWithObjectData(
		notAlgoliaSearchEventType,
		skus,
		prepareObjectData(skus),
	);
};

export default handleNonAlgoliaInsightEvent;
