import productSuggestionsConfig from './productSuggestionsSourceConfig';
import productSuggestionsHeaderTemplate from '../templates/productSuggestionsHeaderTemplate';
import productSuggestionItemTemplate from '../templates/productSuggestionItemTemplate';
import noResultsMessage from '../templates/noResultsMessage';

const productSuggestionsSource = query => {
	return {
		sourceId: 'products',
		getItems() {
			return productSuggestionsConfig(query);
		},
		getItemUrl({ item }) {
			/* eslint no-underscore-dangle: 0 */
			const itemUrl = `/${window?.dataLayer?.[0]?.navigation?.pageLanguage}${item.productUrl}?queryID=${item.__autocomplete_queryID}`;
			return itemUrl;
		},
		templates: {
			header({ items, html }) {
				if (items.length === 0) return null;

				return productSuggestionsHeaderTemplate(html);
			},
			item({ item, html }) {
				return productSuggestionItemTemplate(item, html);
			},
			noResults() {
				return noResultsMessage();
			},
		},
	};
};

export default productSuggestionsSource;
