import {
	removeProductsAfterAlgoliaSearchStorage,
	getProductsAfterAlgoliaSearchStorageData,
} from '../addedToCartObjectIDsAfterSearchStorage';
import prepareObjectData from './prepareObjectData';
import insightEventWithObjectData from './insightEventWithObjectData';
import handleNonAlgoliaInsightEvent from './handleNonAlgoliaInsightEvent';
import retrieveAlgoliaSearchSkusFromStorageData from './retrieveAlgoliaSearchSkusFromStorageData';
import getNonAlgoliaPurchasedProductSkus from '../purchasedObject/getNonAlgoliaPurchasedProductSkus';
import getAlgoliaPurchasedProductSkus from '../purchasedObject/getAlgoliaPurchasedProductSkus';
import excludeAlgoliaProductsDataDeletedFromBasket from '../purchasedObject/excludeAlgoliaProductsDataDeletedFromBasket';

const handleInsightWithObjectData = (
	allProductSkus,
	notAlgoliaSearchEventType,
	algoliaSearchEventType,
) => {
	const maxRecordsQuantity = 20;
	const afterAlgoliaSearchStorageData =
		getProductsAfterAlgoliaSearchStorageData();

	if (!allProductSkus) {
		removeProductsAfterAlgoliaSearchStorage();
		return;
	}

	if (!afterAlgoliaSearchStorageData) {
		handleNonAlgoliaInsightEvent(
			maxRecordsQuantity,
			allProductSkus,
			notAlgoliaSearchEventType,
		);
	} else {
		const afterAlgoliaSearchSkus = retrieveAlgoliaSearchSkusFromStorageData(
			afterAlgoliaSearchStorageData,
		);

		const afterAlgoliaSearchPurchasedSkus = getAlgoliaPurchasedProductSkus(
			afterAlgoliaSearchSkus,
			allProductSkus,
		);

		if (afterAlgoliaSearchPurchasedSkus.length !== allProductSkus.length) {
			const nonAlgoliaSkus = getNonAlgoliaPurchasedProductSkus(
				afterAlgoliaSearchPurchasedSkus,
				allProductSkus,
			);

			handleNonAlgoliaInsightEvent(
				maxRecordsQuantity,
				nonAlgoliaSkus,
				notAlgoliaSearchEventType,
			);
		}

		if (afterAlgoliaSearchPurchasedSkus.length > 0) {
			const algoliaPurchasedProductsData =
				excludeAlgoliaProductsDataDeletedFromBasket(
					afterAlgoliaSearchStorageData,
					afterAlgoliaSearchPurchasedSkus,
				);

			if (afterAlgoliaSearchPurchasedSkus.length >= maxRecordsQuantity) {
				afterAlgoliaSearchPurchasedSkus.length = maxRecordsQuantity;
				algoliaPurchasedProductsData.length = maxRecordsQuantity;
			}

			insightEventWithObjectData(
				algoliaSearchEventType,
				afterAlgoliaSearchPurchasedSkus,
				prepareObjectData(algoliaPurchasedProductsData),
			);
		}
	}

	removeProductsAfterAlgoliaSearchStorage();
};

export default handleInsightWithObjectData;
